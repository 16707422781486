<template>
  <div>
    <a-card title="出库通知单详情">
      <!-- <a-button slot="extra" type="primary" icon="printer" style="margin-right: 8px" ghost v-print="'#printContent'">
      打印
    </a-button> -->
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>
      <section id="printContent">
        <a-spin :spinning="spinning">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
          <a-descriptions bordered>
            <a-descriptions-item label="出库通知单号">
              {{ detailInfo.number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ detailInfo.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ detailInfo.warehouse_name }}
            </a-descriptions-item>
            <!-- <a-descriptions-item label="库区">
              {{ detailInfo.area_name }}
            </a-descriptions-item> -->
            <a-descriptions-item label="创建人">
              {{ detailInfo.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ detailInfo.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="客户单号">
              {{ detailInfo.external_number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户联系人">
              {{ detailInfo.customer_contact }}
            </a-descriptions-item>
            <a-descriptions-item label="联系人电话">
              {{ detailInfo.contact_phone }}
            </a-descriptions-item>
            <a-descriptions-item label="出库日期">
              {{ detailInfo.delivery_date }}
            </a-descriptions-item>
            <a-descriptions-item label="到货日期">
              {{ detailInfo.arrival_date }}
            </a-descriptions-item>
            <a-descriptions-item label="送货地址">
              {{ detailInfo.receiving_address }}
              <a-button
                v-if="detailInfo.receiving_address"
                type="link"
                icon="copy"
                v-clipboard:copy="detailInfo.receiving_address"
                v-clipboard:success="copySuccess"
              />
            </a-descriptions-item>
            <a-descriptions-item label="配送方式">
              {{ detailInfo.delivery_method }}
            </a-descriptions-item>
            <a-descriptions-item label="承运公司">
              {{ detailInfo.express }}
            </a-descriptions-item>
            <a-descriptions-item label="物流单号">
              {{ detailInfo.logistics_number }}
            </a-descriptions-item>
            <a-descriptions-item label="包装要求">
              {{ detailInfo.packaging_requirements }}
            </a-descriptions-item>
            <a-descriptions-item label="送货时间">
              {{ detailInfo.delivery_time }}
            </a-descriptions-item>
            <a-descriptions-item label="总复核数量">
              {{ detailInfo.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ detailInfo.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="附件" :span="3">
              <a
                v-for="fileItem in detailInfo.delivery_file_items"
                :href="fileItem.file"
                :key="fileItem.id"
                style="margin-right: 16px"
                >{{ fileItem.name }}</a
              >
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>

        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="materialItems"
            :loading="materialLoading"
            :pagination="false"
          >
            <div slot="material_weight" slot-scope="value, item">
              <span v-if="item.total_quantity && item.material_net_weight">
                {{ NP.times(item.total_quantity, item.material_net_weight, 0.001) }}
              </span>
            </div>
            <div slot="material_volume" slot-scope="value, item">
              <span v-if="item.total_quantity && item.material_volume">
                {{ NP.times(item.total_quantity, item.material_volume) }}
              </span>
            </div>

            <div slot="action" slot-scope="value, item">
              <a-button
                type="primary"
                size="small"
                :disabled="item.total_quantity == item.remain_quantity + item.return_quantity"
                @click="
                  () => {
                    stockOutReturnVisible = true;
                    deliveryMaterial = item.id;
                  }
                "
                >退库</a-button
              >
            </div>
          </a-table>
        </div>
      </section>
    </a-card>

    <stock-out-return-modal
      v-model="stockOutReturnVisible"
      :deliveryMaterial="deliveryMaterial"
      :warehouse="detailInfo.warehouse"
      @cancel="
        stockOutReturnVisible = false;
        getDeliveryMaterial();
      "
    />
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { deliveryDetail, deliveryMaterials } from "@/api/stockOut";
import NP from "number-precision";

export default {
  components: {
    StockOutReturnModal: () => import("@/components/StockOutReturnModal"),
  },
  data() {
    return {
      NP,
      description: "详情",
      spinning: false,
      materialLoading: false,
      detailInfo: {},
      materialItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
          key: "material_name",
          width: 220
        },
        {
          title: "产品英文名称",
          dataIndex: "material_english_name",
          key: "material_english_name",
          width: 220
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: "质检状态",
          dataIndex: "status_display",
          key: "status_display",
          width: 50
        },
        {
          title: "批次",
          dataIndex: "batch_number",
          key: "batch_number",
          width: 120
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
          key: "production_date",
        },
        {
          title: "应复核数量",
          dataIndex: "total_quantity",
          key: "total_quantity",
          width: 60
        },
        {
          title: "剩余复核数量",
          dataIndex: "remain_quantity",
          key: "remain_quantity",
          width: 60
        },
        {
          title: "退库数量",
          dataIndex: "return_quantity",
          key: "return_quantity",
          width: 60
        },
        {
          title: "单位",
          dataIndex: "material_unit",
          key: "material_unit",
          width: 40
        },
        {
          title: "总重量(吨)",
          dataIndex: "material_weight",
          key: "material_weight",
          scopedSlots: { customRender: "material_weight" },
        },
        {
          title: "总体积(立方)",
          dataIndex: "material_volume",
          key: "material_volume",
          scopedSlots: { customRender: "material_volume" },
        },
        {
          title: "包装规格",
          dataIndex: "material_spec",
          key: "material_spec",
        },
        {
          title: "库存状态",
          dataIndex: "location_number",
          key: "location_number",
          customRender: (value, item, index) => {
            let recommendLocations = [];
            for (let _item of item.recommend_location_items) {
              if (_item.has_stock) {
                recommendLocations.push(`${_item.location_number}: ${_item.remain_quantity}`);
              }
            }

            return recommendLocations.join(",");
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      deliveryMaterial: null,
      stockOutReturnVisible: false,
    };
  },
  computed: {
    totalWeight() {
      let totalWeight = 0;
      for (let item of this.materialItems) {
        if (item.material_net_weight) {
          totalWeight = NP.plus(totalWeight, NP.times(item.total_quantity, item.material_net_weight, 0.001));
        }
      }
      return totalWeight;
    },
    totalVolume() {
      let totalVolume = 0;
      for (let item of this.materialItems) {
        if (item.material_volume) {
          totalVolume = NP.plus(totalVolume, NP.times(item.total_quantity, item.material_volume));
        }
      }
      return totalVolume;
    },
  },
  methods: {
    initData() {
      this.getData(this.$route.query.id);
    },
    getData(id) {
      this.spinning = true;
      this.materialLoading = true;
      document.getElementById("jsbarcode").html = "";
      deliveryDetail({ id })
        .then((data) => {
          this.detailInfo = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.spinning = false;
        });
      deliveryMaterials({ id })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    getDeliveryMaterial() {
      const deliveryOrder = this.$route.query.id;
      deliveryMaterials({ id: deliveryOrder })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    copySuccess() {
      this.$message.success("复制成功");
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style>
.jsbarcode {
  display: inline-block;
  float: right;
}
</style>
